
import Vue from "vue";
import IPeopleAddressType from "@/lib/interfaces/people-address-type";
import PeopleAddressTypeList from "@/components/subviews/people-address-types/people-address-types-list.vue";
import PeopleAddressTypeMaintenance from "@/components/subviews/people-address-types/people-address-types-maintenance.vue";

export default Vue.extend({
  name: "PeopleAddressTypes",

  components: { PeopleAddressTypeList, PeopleAddressTypeMaintenance },

  data() {
    return {
      refresh: 0,

      currentTab: 0,

      peopleAddressType: {} as IPeopleAddressType,
    };
  },
});


import Api from "@/lib/api";
import { Rq } from "@/lib/amdt";
import Vue, { PropType } from "vue";
import { DataOptions } from "vuetify";
import vAlertMessage from "@/components/layout/v-alert-message.vue";
import IPeopleAddressType from "@/lib/interfaces/people-address-type";
import ATextField from "@/components/elements/a-text-field.vue";
import ABtn from "@/components/elements/a-btn.vue";
import ADataTable from "@/components/elements/a-data-table.vue";
import ACard from "@/components/elements/a-card.vue";
import ASelectStatus from "@/components/elements/a-select-status.vue";

export default Vue.extend({
  name: "PeopleAddressTypesList",

  components: {
    vAlertMessage,
    ATextField,
    ABtn,
    ADataTable,
    ACard,
    ASelectStatus,
  },

  props: {
    peopleAddressType: {
      type: Object as PropType<IPeopleAddressType>,
      required: true,
    },

    forceRefresh: {
      type: Number as PropType<number>,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      ret_id: 1,
      ret_msg: "",

      total: 0,
      search: "",
      desativado: "0",

      options: {} as DataOptions,
      peoplesAddressTypes: new Array<IPeopleAddressType>(),

      headers: [
        { text: "Descrição", value: "descricao" },
        { text: "Ordem", value: "eorder" },
        { text: "Status", value: "cDesativado", sortable: false },
        { text: "Ações", value: "actions", sortable: false },
      ],
    };
  },

  watch: {
    forceRefresh() {
      this.getPeoplesAddressTypes().then();
    },

    options: {
      deep: true,
      handler() {
        this.getPeoplesAddressTypes().then();
      },
    },
  },

  computed: {
    cPeopleAddressTypes(): Array<Record<string, unknown>> {
      return this.peoplesAddressTypes.map((peopleAddressType) => ({
        ...peopleAddressType,

        cDesativado: peopleAddressType.desativado === "0" ? "Ativo" : "Inativo",
      }));
    },
  },

  methods: {
    async openPeopleAddressType(id: string) {
      const peopleAddressType = this.peoplesAddressTypes.find(
        (peopleAddressType) => peopleAddressType.id === id
      );
      this.$emit("update:people-address-type", { ...peopleAddressType });
    },

    async getPeoplesAddressTypes() {
      try {
        this.loading = true;
        this.ret_id = 1;
        this.ret_msg = "";

        const rq = new Rq("pessoas_enderecos_tipos.read", {
          search: this.search,
          desativado: this.desativado,
          itemsPerPage: this.options.itemsPerPage.toString(),
          page: this.options.page.toString(),
          sortBy: this.options.sortBy[0] ?? "id",
          sortOrder: this.options.sortDesc[0] ? "DESC" : "ASC",
        });
        const rsp = await Api.request(rq);

        if (rsp.ret_id < 1) {
          this.ret_id = rsp.ret_id;
          this.ret_msg = rsp.ret_msg;
          return;
        }

        this.total = Number(rsp.params["total"]);
        this.options.itemsPerPage = Number(rsp.params["itemsPerPage"]);
        this.options.page = Number(rsp.params["page"]);

        this.peoplesAddressTypes =
          rsp.getTable("pessoas_enderecos_tipos")?.getRowsObject() ?? [];
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
  },
});
